import { flow, merge, pickBy, identity } from 'lodash/fp';
import { captureException, withScope } from '@sentry/react';

const { NODE_ENV } = process.env;
const isDev = NODE_ENV === 'development';

/**
 * Merge user passed tags with an object
 * of default tags for sentry, then cleanup undefined values
 * */
export const withDefaultTags = (newTags = {}) =>
  flow(
    merge({
      level: 'error',
      environment: NODE_ENV,
      release: isDev ? 'development' : process.env.npm_package_version
    }),
    pickBy(identity)
  )(newTags);

/** Allow use of a functional tag resolver  */
function getTags(error, config = {}) {
  const { tags } = config;

  if (tags instanceof Function) {
    return withDefaultTags(tags(error));
  }

  return withDefaultTags(tags);
}

export function taggedException(error, config) {
  return withScope((scope) => {
    Object.entries(getTags(error, config))
      // Tags will not be set globally in sentry (only for scope)
      .forEach(([tag, value]) => {
        scope.setTag(tag, value);
      });

    captureException(error);
  });
}

export const withWrapper =
  (fn, config) =>
  (...args) => {
    try {
      return fn(...args);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      // Send to sentry
      taggedException(error, config);
      throw error;
    }
  };

export const withAsyncWrapper =
  (fn, config) =>
  async (...args) => {
    try {
      return await fn(...args);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      // Send to sentry
      taggedException(error, config);
      throw error;
    }
  };

export default { withWrapper, withAsyncWrapper };

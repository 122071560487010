import custom from './custom';
import redirect from './redirect';
import hubspot from './hubspot';

import { withAsyncWrapper } from '../sentryWrapper';

async function providers(provider, data, callback) {
  const { type, parameters } = provider;

  switch (type) {
    case 'custom':
      return custom({ ...parameters, data });
    case 'hubspot':
      return hubspot({ ...parameters, data });
    case 'redirect':
      return redirect({ ...parameters, data });
    case 'callback':
      if (callback) return callback({ ...parameters, data });
      return null;
    default:
      return null;
  }
}

export default withAsyncWrapper(providers, {
  tags: () => ({
    page: window.location.href
  })
});

/* global document */
import pickBy from 'lodash/fp/pickBy';
import identity from 'lodash/fp/identity';

const cleanup = pickBy(identity);

const getUTMParameters = () => {
  const queryString = window.location.search.substring(1);
  const params = {};
  const pairs = queryString.split('&');
  if (pairs?.length === 0) return {};

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1] || '');

    // Check if the key starts with "utm_"
    if (key.indexOf('utm_') === 0) {
      params[key] = value;
    }
  }

  return params;
};

const templateStrings = {
  '{urlId}': () => new URL(document?.location?.href).pathname
};

/**
 * Some values have template strings
 * This function should map over the values
 * find which are stringy and then replace with the
 * correct value from the current window state
 * */
export function enrichData(data) {
  Object.keys(data).forEach((key) => {
    const item = data[key];
    if (typeof item !== 'string') return;

    // Map over every template string and replace the value
    // such as {urlId} with the function return value in the dictionary above
    Object.entries(templateStrings).forEach(([templateString, valueFn]) => {
      if (item.includes(templateString)) {
        // eslint-disable-next-line no-param-reassign
        data[key] = data[key].replace(templateString, valueFn());
      }
    });
  });

  const dataWithUtm = { ...data, ...getUTMParameters() };

  return cleanup(dataWithUtm);
}

export default enrichData;

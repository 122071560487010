import axios from 'axios';
import { Cookies } from 'react-cookie';
import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

const cookies = new Cookies();

function getUTMparams(referrerUrl) {
  const result = {};

  try {
    const url = new URL(referrerUrl);

    result.utm_source = url.searchParams.get('utm_source');
    result.utm_medium = url.searchParams.get('utm_medium');
    result.utm_campaign = url.searchParams.get('utm_campaign');
    result.utm_term = url.searchParams.get('utm_term');
    result.utm_content = url.searchParams.get('utm_content');
  } catch (err) {
    // continue regardless of error
  }
  return result;
}

/**
 * Add UTM
 * parameters from the URL as field values
 * */
function addUtmData(fields) {
  const utm = pickBy(getUTMparams(get(document.location, 'href')), identity);
  return { ...fields, ...utm };
}

function addUserIp(context) {
  // Access the userIP from the Redux store using useSelector
  const userIP = '127.0.0.1'; // useSelector((state) => state.app?.locale?.userIP || '127.0.0.1');
  // Add the user IP to the context
  return { ...context, ipAddress: userIP };
}

function buildContext() {
  let hutk;
  let pageUri;
  let pageName;
  try {
    hutk = cookies.get('hubspotutk');
  } catch (err) {
    // continue regardless of error
  }
  try {
    pageUri = `${get(document, 'location.origin')}${get(document, 'location.pathname')}`;
  } catch (err) {
    // continue regardless of error
  }
  try {
    pageName = get(document, 'title');
  } catch (err) {
    // continue regardless of error
  }
  return {
    hutk,
    pageUri,
    pageName
  };
}

function addContext(fields) {
  return { ...fields, context: addUserIp(buildContext()) };
}

async function redirect({ url, data }) {
  const withUTMData = addUtmData(data);
  const withContext = addContext(withUTMData);

  return axios.post(url, JSON.stringify(withContext), {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export function numberWithCommas(x = '') {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default redirect;

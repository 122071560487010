import PropTypes from 'prop-types';
import { ModuleTextPropTypes } from '../ModuleText';
import { ImagePropTypes } from '../Image';
import { ElementLinkPropTypes } from '../ElementLink';

const ModuleFormPropTypes = {
  formSettings: PropTypes.object.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  preSubmitContent: PropTypes.object,
  successContent: PropTypes.object.isRequired,
  headerTextModule: PropTypes.shape(ModuleTextPropTypes),
  image: PropTypes.shape(ImagePropTypes),
  links: PropTypes.shape(ElementLinkPropTypes),
  disclaimer: PropTypes.string,
  backgroundColor: PropTypes.string,
  handleSubmit: PropTypes.func,
  callback: PropTypes.func,
  customized: PropTypes.bool,
  hasReadyDisplay: PropTypes.bool,
  formLocation: PropTypes.string
};

export const FormPropTypes = {
  ...ModuleFormPropTypes,
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  anchor: PropTypes.string
};

export default ModuleFormPropTypes;

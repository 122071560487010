/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { merge, curry } from 'lodash/fp';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Loader from '../Loader';
import ModuleFormPropTypes from './ModuleFormPropTypes';
import providers, { enrichData } from '../../utils/forms';
import styles from './ModuleForm.module.scss';
import sidekickInit from '../../utils/sidekick/init';
import { useAppProvider } from '../../../AppProvider/AppProvider.tsx';

import ContentModule from '../ContentModule';

const FormFooter = dynamic(() => import('./Templates/FormFooter'));
const FormFooterWithImage = dynamic(() => import('./Templates/FormFooterWithImage'));
const FormHero = dynamic(() => import('./Templates/FormHero'));
const FormInline = dynamic(() => import('./Templates/FormInline'));
const FormNewLocation = dynamic(() => import('./Templates/FormNewLocation'));
const FormNewsletter = dynamic(() => import('./Templates/FormNewsletter'));
const FormSearch = dynamic(() => import('./Templates/FormSearch'));
const FormBox = dynamic(() => import('./Templates/FormBox'));

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;

const mappings = {
  Footer: FormFooter,
  Hero: FormHero,
  Inline: FormInline,
  NewLocation: FormNewLocation,
  Newsletter: FormNewsletter,
  Search: FormSearch,
  Box: FormBox,
  FooterWithImage: FormFooterWithImage
};

export const FormPropTypes = {
  ...ModuleFormPropTypes,
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  internalTitle: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  anchor: PropTypes.string,
  callback: PropTypes.func,
  customized: PropTypes.bool,
  formLocation: PropTypes.string
};

const SUBMISSION_STATES = {
  NONE: null,
  SUBMITTED: 'SUBMITTED',
  LOADING: 'PENDING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
};

const ErrorContent = {
  _id: '90cb379b-dfe5-49c6-8623-99c49910aeb3',
  _contentTypeId: 'moduleText',
  textJustification: 'center',
  title: 'Unfortunately we encountered an error :( Please try again later!',
  supertitle: null,
  body: null,
  bodySize: 'small'
};

const formatObjectToString = (inputObj) => {
  if (!inputObj) return 'Unknown';
  const result = [];

  Object.entries(inputObj).forEach(([key, value]) => {
    if (typeof value === 'object') {
      result.push(`${key}-${formatObjectToString(value)}`);
    } else {
      result.push(`${key}-${value}`);
    }
  });

  return result.join('|');
};

const ConditionalWrap = ({ condition, wrap, children }) => (condition ? wrap(children) : children);
function ModuleForm(props) {
  const router = useRouter();
  const [submitState, setSubmitState] = useState(SUBMISSION_STATES.NONE);
  const { formSettings, customized, image, backgroundColor } = props;
  const [formS, setFormS] = useState(formSettings);
  const [showRecaptchaV2, setShowRecaptchaV2] = useState(false);
  const recaptchaRef = useRef(null);
  const [isVerified, setIsVerified] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { contentGroup } = useAppProvider();
  const routerQuery = router && router.query;

  const { _id, _contentTypeId, internalTitle, anchor, preSubmitContent, formLocation } = props;

  const formSuccessWrapId = useMemo(() => `${_id}-ready`, [_id]);

  if (!formSettings) {
    // eslint-disable-next-line no-console
    console.error('formSettings has no value, form will not render');
    return null;
  }

  const { formStyle } = props;
  const Form = mappings[formStyle];

  if (!Form) {
    // eslint-disable-next-line no-console
    console.error(`Invalid formStyle value : ${formStyle}`);
    return null;
  }

  const { sidekicker } = sidekickInit({ _id, _contentTypeId, internalTitle });

  let readyDisplay = '';

  // Form already submitted
  switch (submitState) {
    case SUBMISSION_STATES.SUCCESS: {
      const { successContent } = props;
      readyDisplay = successContent && (
        <div
          className={cx(styles.success, styles.ready, image && styles.readyWithImage)}
          {...sidekicker('Success Content')}>
          <ContentModule contentTypeId={successContent._contentTypeId} fields={successContent} />
        </div>
      );
      break;
    }

    case SUBMISSION_STATES.LOADING:
      readyDisplay = (
        <div className={cx(styles.ready, image && styles.readyWithImage, 'd-block')}>
          <Center>
            <Loader />
          </Center>
        </div>
      );
      break;

    case SUBMISSION_STATES.ERROR:
      readyDisplay = (
        <div className={cx(styles.error, styles.ready, image && styles.readyWithImage)}>
          <ContentModule contentTypeId={ErrorContent._contentTypeId} fields={ErrorContent} />
        </div>
      );
      break;

    default:
      break;
  }

  // NOTE: Currently only for FormFooter (blue is default)
  const { callback } = props;
  const sectionBgColor = formStyle === 'Footer' || backgroundColor ? backgroundColor : '#09f';

  /** Most forms have the same behavior when submitting */
  const handleSubmit = curry(async (options, data) => {
    try {
      const token = await executeRecaptcha('SUBMIT_FORM');
      const response = await axios.post('/api/verify_captcha', { token });
      if (!response.data.success) {
        // show recaptcha V2
        setShowRecaptchaV2(true);
        return;
      }
    } catch (err) {
      // NOOP
      console.log('Recaptcha error', err);
      return;
    }
    const { provider } = formSettings;
    const enrichedData = merge(options || {}, enrichData(data));

    setSubmitState(SUBMISSION_STATES.LOADING);

    providers(provider, enrichedData, callback)
      .then(() => {
        setSubmitState(SUBMISSION_STATES.SUCCESS);

        // Scroll to element
        const $el = global.document.getElementById(formSuccessWrapId);
        const bodyRect = global.document.body.getBoundingClientRect().top;
        const elementRect =
          ($el && $el.getBoundingClientRect() && $el.getBoundingClientRect().top) || 0;
        const elementPosition = elementRect - bodyRect;

        global.window.scrollTo({
          top: elementPosition,
          behavior: 'smooth'
        });
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'FormSubmission',
            SubmissionStatus: 'Successful',
            formName: internalTitle,
            FormIdentifier: _id
          });
          window.dataLayer.push({
            event: props?.formSubmitEventName ?? 'form_submit',
            form_id: formSettings?.provider?.parameters?.formId ?? _id,
            form_name: props?.formName ?? props?.internalTitle,
            form_destination:
              formSettings?.provider?.parameters?.url ??
              formatObjectToString(formSettings?.provider),
            form_submit_text: props?.successContent?.title ?? 'Unknown',
            form_location: formLocation,
            content_group: contentGroup
          });
        }
      })
      .catch((error) => {
        setSubmitState(SUBMISSION_STATES.ERROR);
        // eslint-disable-next-line
        console.error(error);

        if (window.dataLayer)
          window.dataLayer.push({
            event: 'FormSubmission',
            SubmissionStatus: 'Error',
            formName: internalTitle,
            FormIdentifier: _id,
            formErrors: error
          });
      });
  });

  const handleCaptchaSubmission = async (token) => {
    try {
      const response = await axios.post('/api/verify_captcha', { token, version: 'v2' });
      if (response.data.success) {
        setIsVerified(true);
      } else {
        setIsVerified(false);
      }
    } catch (err) {
      setIsVerified(false);
    }
  };

  const handleFocus = () => {
    if (!window) return;
    const formStarted = window.sessionStorage.getItem(`${_id}:form_start`);
    if (formStarted) {
      return;
    }

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'form_start',
        form_id: formSettings?.provider?.parameters?.formId ?? _id,
        form_name: props?.formName ?? props?.internalTitle,
        form_destination:
          formSettings?.provider?.parameters?.url ?? formatObjectToString(formSettings?.provider),
        content_group: contentGroup
      });
      window.sessionStorage.setItem(`${_id}:form_start`, true);
    }
  };

  useEffect(() => {
    let queryFromRoute = {};
    if (router) {
      const { query } = router;
      queryFromRoute = query;
    }
    const keys = Object.keys(queryFromRoute);
    if (formSettings && formSettings.steps) {
      const newFS = {
        ...formSettings,
        steps: formSettings.steps.map((step) => ({
          ...step,
          fields: step.fields.map((field) => {
            if (keys.includes(field.name)) {
              if (field.type === 'hidden') return { ...field, value: queryFromRoute[field.name] };
              return { ...field, defaultValue: queryFromRoute[field.name] };
            }
            if (field.type === 'toggleable' && keys.includes(field.field?.name))
              return {
                ...field,
                field: { ...field.field, defaultValue: queryFromRoute[field.field?.name] }
              };
            return { ...field };
          })
        }))
      };
      setFormS(newFS);
    } else setFormS(formSettings);
  }, [routerQuery, formSettings]);

  let readyDisplayCn = `form-${formStyle}`;
  let readyBackdropCn = '';

  if (readyDisplay) {
    readyDisplayCn = cx(styles.readyOverlay, styles[formStyle.toLowerCase()], `form-${formStyle}`);

    readyBackdropCn = cx(
      'ready-backdrop',
      image && 'ready-backdrop--with-image',
      submitState === SUBMISSION_STATES.LOADING && 'ready-backdrop--loading'
    );
  }

  return (
    <>
      {preSubmitContent && (
        <div className={cx('pt-3', styles.moduleText)} {...sidekicker('Pre Submit Content')}>
          <ContentModule
            contentTypeId={preSubmitContent._contentTypeId}
            fields={preSubmitContent}
          />
        </div>
      )}
      <ConditionalWrap
        condition={readyDisplay}
        wrap={(children) => (
          <div
            className={cx(
              'form-wrap',
              styles.formWrap,
              formStyle === 'Hero' ? styles.heroWrap : null
            )}
            id={formSuccessWrapId}>
            {children}
          </div>
        )}>
        <div
          data-testid="ModuleForm"
          id={anchor ? anchor.toLowerCase() : undefined}
          className={cx(readyDisplayCn, 'pb-5 pb-md-0')}
          style={{
            backgroundColor:
              (formStyle === 'Footer' || formStyle === 'FooterWithImage') && sectionBgColor
          }}
          onFocus={handleFocus}
          {...sidekicker('Module Form')}>
          {readyDisplay && (
            <div
              className={readyBackdropCn}
              style={
                submitState !== SUBMISSION_STATES.ERROR ? { backgroundColor: sectionBgColor } : {}
              }
            />
          )}
          <ConditionalWrap
            condition={readyDisplay}
            wrap={(children) => <fieldset disabled>{children}</fieldset>}>
            <Form
              sectionBgColor={formStyle === 'Footer' ? sectionBgColor : null}
              headerText={props.headerTextModule}
              {...props}
              formSettings={formS}
              handleSubmit={handleSubmit}
              customized={customized}
              hasReadyDisplay={!!readyDisplay}
              isDisabled={showRecaptchaV2 && !isVerified}>
              {showRecaptchaV2 && (
                <ReCAPTCHA
                  sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_V2_SITE_KEY}
                  ref={recaptchaRef}
                  onChange={handleCaptchaSubmission}
                />
              )}
            </Form>
          </ConditionalWrap>
        </div>
        {readyDisplay}
      </ConditionalWrap>
    </>
  );
}

ModuleForm.propTypes = FormPropTypes;
ModuleForm.defaultProps = {
  backgroundColor: null,
  anchor: '',
  callback: null,
  customized: false,
  formLocation: 'inline'
};

export default ModuleForm;

import axios from 'axios';
import queryString from 'query-string';

// const PROMOCODES_API = `${process.env.NEXT_PUBLIC_CLOUDFUNCTIONS_BASE_URL}/promocodes`;
const PROMOCODES_API = `/api/v1/directmail`;

/**
 * Pipe for promised functions
 * Pass each function result to the next function in ...functions
 *
 * pipeP(a, b, c)({})
 * would be equivalent to
 *
 * let result = {};
 * result = await a(result)
 * result = await b(result)
 * result = await c(result)
 * */
const pipeP =
  (...functions) =>
  async (data) => {
    let result = data;

    // eslint-disable-next-line no-restricted-syntax
    for (const fn of functions) {
      // eslint-disable-next-line no-await-in-loop
      result = await fn(result);
    }

    return result;
  };

const directMailPromocode = ({ name }) => name === 'promocode';

const directMailTableId = ({ name }) => name === 'table_id';

/**
 * Retrieve additional information about the direct mail campaign
 * from the internal API
 * */
async function getDirectMailData(fields) {
  const promocode = fields.find(directMailPromocode);
  if (!promocode) return fields;
  const tableId = fields.find(directMailTableId);
  if (!tableId) return fields;

  try {
    const {
      data: { result }
    } = await axios.get(`${PROMOCODES_API}/${tableId.value}?code=${promocode.value}`);
    return fields.concat(Object.keys(result).map((key) => ({ name: key, value: result[key] })));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Fetching direct mail data:', error.message);
    return fields;
  }
}

/** Remove empty fields */
function cleanup(fields) {
  return fields.filter((field) => Object.keys(field).length > 0);
}

/** Transform the {key:value} form set to an array of {name, value} */
function buildFields(data) {
  return Object.keys(data).map((key) => ({ name: key, value: data[key] }));
}

/** Transform the {name, value} to a query string */
function buildQueryString(fields) {
  return queryString.stringify(
    fields.reduce((prev, curr) => {
      return { ...prev, [curr.name]: curr.value };
    }, {})
  );
}

async function redirect({ url, data }) {
  const fields = await pipeP(
    // Step through concatenation of different additional data
    getDirectMailData,
    cleanup
  )(buildFields(data));

  const q = buildQueryString(fields);

  const isInternal = url && url.startsWith('/');

  if (isInternal) {
    const { default: Router } = await import('next/router');
    Router.push({
      pathname: url,
      query: fields.reduce((prev, curr) => {
        return { ...prev, [curr.name]: curr.value };
      }, {})
    });
  } else window.location.href = `${url}?${q}`;
}

export default redirect;

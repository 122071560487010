import axios from 'axios';
import { Cookies } from 'react-cookie';
import negate from 'lodash/negate';
import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pick';
import { useSelector } from 'react-redux';

const cookies = new Cookies();

const DATASSENTIAL_API = `/api/v1/firefly`;

/**
 * Pipe for promised functions
 * Pass each function result to the next function in ...functions
 *
 * pipeP(a, b, c)({})
 * would be equivalent to
 *
 * let result = {};
 * result = await a(result)
 * result = await b(result)
 * result = await c(result)
 * */
const pipeP =
  (...functions) =>
  async (data) => {
    let result = data;

    // eslint-disable-next-line no-restricted-syntax
    for (const fn of functions) {
      // eslint-disable-next-line no-await-in-loop
      result = await fn(result);
    }

    return result;
  };

function getUTMparams(referrerUrl) {
  const result = {};

  try {
    const url = new URL(referrerUrl);

    result.utm_source = url.searchParams.get('utm_source');
    result.utm_medium = url.searchParams.get('utm_medium');
    result.utm_campaign = url.searchParams.get('utm_campaign');
    result.utm_term = url.searchParams.get('utm_term');
    result.utm_content = url.searchParams.get('utm_content');
  } catch (err) {
    // continue regardless of error
  }

  return result;
}

/** Transform the {key:value} form set to an array for hubspot */
function buildFields(data) {
  return Object.keys(data).map((key) => ({ name: key, value: data[key] }));
}

function buildContext() {
  let hutk;
  let pageUri;
  let pageName;
  try {
    hutk = cookies.get('hubspotutk');
  } catch (err) {
    // continue regardless of error
  }
  try {
    pageUri = `${get(document, 'location.origin')}${get(document, 'location.pathname')}`;
  } catch (err) {
    // continue regardless of error
  }
  try {
    pageName = get(document, 'title');
  } catch (err) {
    // continue regardless of error
  }
  return {
    hutk,
    pageUri,
    pageName
  };
}

function addUserIp(context) {
  // Access the userIP from the Redux store using useSelector
  const userIP = '127.0.0.1'; // useSelector((state) => state.app?.locale?.userIP || '127.0.0.1');
  // Add the user IP to the context
  return { ...context, ipAddress: userIP };
}

/**
 * Add UTM
 * parameters from the URL as field values
 * */
function addUtmData(fields) {
  const utm = pickBy(getUTMparams(get(document.location, 'href')), identity);
  return fields.concat(Object.keys(utm).map((key) => ({ name: key, value: utm[key] })));
}

const isCompany = ({ name }) => name === 'company';

/**
 * Retrieve additional information about the google
 * place from the internal API
 * */
async function extractDatassentialId(fields) {
  const company = fields.find(isCompany);
  if (!company) return fields;

  try {
    const {
      data: { placeDetails = {} }
    } = await axios.post(
      DATASSENTIAL_API,
      {
        Headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      },
      { googlePlaceID: company.value.id }
    );
    return fields.concat({ name: 'datassential_id', value: placeDetails.FFID });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Fetching datassential_id:', error.message);
    return fields;
  }
}

/**
 * For some hubspot forms we require both the
 * google placeID and the google place label.
 *
 * In this case we're gonna save the whole place to the form
 * state and then extract at the last moment before sending the data
 */
function extractBusiness(fields) {
  const company = fields.find(isCompany);
  if (!company) return fields;

  // Not dealing with a JSON company
  // or dealing with a string label of the company
  if (company.value instanceof Object === false) {
    return fields;
  }

  return fields.filter(negate(isCompany)).concat(
    [
      {
        name: 'business_address',
        value: company.value.address
      },
      {
        name: 'country',
        value: company.value.country
      },
      {
        name: 'company',
        value: company.value.company
      },
      {
        name: 'state',
        value: company.value.state
      },
      {
        name: 'google_place_id__c',
        value: company.value.id
      },
      {
        name: 'zip',
        value: company.value.zip
      },
      {
        name: 'phone',
        value: company.value.phone
      }
    ].filter(({ value }) => value)
  );
}

/** Remove empty fields */
function cleanup(fields) {
  return fields.filter((field) => Object.keys(field).length > 0);
}

async function hubspot({ portalId, formId, data }) {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  const fields = await pipeP(
    // Step through concatenation of different additional data
    addUtmData,
    extractDatassentialId,
    extractBusiness,
    cleanup
  )(buildFields(data));

  const body = JSON.stringify({
    fields,

    // TODO: Should we be removing this?
    skipValidation: true,
    context: addUserIp(buildContext())
  });

  return axios.post(url, body, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export default hubspot;
